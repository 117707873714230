import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"
import IndustriesHero from "../components/hero/industriesHero/industriesHero"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { useTranslation } from "../helpers/useTranslation"
import { findAvailableModule } from "../helpers/findAvailableModule"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const Industry = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { content, seo } = data.sanityIndustry.tabs
  const translations = data.sanityTranslationIndustry?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const link = content.market === "en-us" ? "industries" : `${content.market}/industries`
  const { _updatedAt, _createdAt } = data.sanityIndustry
  const image = content._rawHeroImage
  const category = content.theme === "work" ? "work" : "education"
  const availableFaq = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")

  const steps = [
    {
      link,
      title: "Industries",
    },
    {
      link: `${link}/${category}/${pageContext.slug}`,
      title: content.title,
    },
  ]

  return (
    <Layout
      theme={content.theme}
      path={generatePath(pageContext.page)}
      translations={translations}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo
        title={content?._rawHero?.title}
        isBlogPost={false}
        image={image?.asset.url}
        path={generatePath(pageContext.page)}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        breadcrumbs={steps}
        faq={availableFaq}
        {...seo}
      />
      <Breadcrumbs
        homePageLabel={t("industries")}
        steps={[{ path: "/industries", label: t("industries") }, { label: content.title }]}
      />
      <IndustriesHero hero={content._rawHero} heroImage={content._rawHeroImage} />
      {content._rawPageBuilder && <PageBuilder pageBuilder={content._rawPageBuilder} />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $_id: String!) {
    sanityIndustry(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _id
      _updatedAt(formatString: "MMMM Do, YYYY")
      _createdAt(formatString: "MMMM Do, YYYY")
      tabs {
        content {
          title
          market
          theme
          _rawHero(resolveReferences: { maxDepth: 10 })
          _rawHeroImage(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    sanityTranslationIndustry(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        _id
        _type
        tabs {
          content {
            market
            theme
            slug {
              current
            }
          }
        }
      }
    }
  }
`

export default Industry
