import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Section from "../../section/section"
import HeroWithIcons from "../heroWithIcons"

import Learning from "../../../../assets/svgs/learning.svg"
import CoOpLearning from "../../../../assets/svgs/co-op-learning.svg"
import Book from "../../../../assets/svgs/book-learning.svg"
import ListEdit from "../../../../assets/svgs/list-edit.svg"
import PCGraph from "../../../../assets/svgs/pc-graph.svg"
import CupStar from "../../../../assets/svgs/cup-star.svg"

import * as styles from "./industriesHero.module.scss"

const parseImage = (Svg, key) => ({
  renderIcon: props => (
    <div key={key} {...props} className={classNames(props.className, styles.icon)}>
      <Svg />
    </div>
  ),
})

function IndustriesHero(props) {
  const { hero, heroImage } = props

  const icons = [
    parseImage(Learning, "1"),
    parseImage(CoOpLearning, "2"),
    parseImage(Book, "3"),
    heroImage,
    parseImage(ListEdit, "5"),
    parseImage(PCGraph, "6"),
    parseImage(CupStar, "7"),
  ]

  if (!hero) return null

  return (
    <Section className={styles.industriesHero} background={hero.background || "none"}>
      {hero && <HeroWithIcons hero={hero} icons={icons} />}
    </Section>
  )
}

IndustriesHero.propTypes = {
  hero: PropTypes.objectOf(PropTypes.any),
  heroImage: PropTypes.objectOf(PropTypes.any),
}

IndustriesHero.defaultProps = {
  hero: null,
  heroImage: null,
}

export default IndustriesHero
